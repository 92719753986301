import React, { useEffect, useState } from "react";
import {
    Box,
    Container,
    FormControl,
    Input,
    InputLabel,
    Modal,
    TextField,
    Tooltip,
    useTheme,
} from "@mui/material";
import { Button, Grid, IconButton, Typography } from "@mui/material";
import { useGetCustomersQuery } from "state/api";
import Header from "components/Header";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DeleteIcon from "@mui/icons-material/Delete";
import { CloudUploadRounded, Delete, Edit, Send } from "@mui/icons-material";
import axios from "axios";
import { toast } from "react-hot-toast";
import { server } from "../../server";
import ShowNews from "./ShowNews";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { Document, Page } from 'react-pdf';

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "1px solid #000",
    boxShadow: 24,
    p: 3,
    borderRadius: 3,
};

const News = () => {
    const theme = useTheme();
    const [data, setData] = useState([])
    const [prevData, setPrevData] = useState();
    const [loading, setLoading] = useState(false);
    const [textField1Value, setTextField1Value] = useState("");
    const [textField2Value, setTextField2Value] = useState("");
    const [textField3Value, setTextField3Value] = useState("");
  
    const handleTextField1Change = (event) => {
        setTextField1Value(event.target.value);
    };

    const handleTextField2Change = (event) => {
        setTextField2Value(event.target.value);
    };
    const handleTextField3Change = (event) => {
        setTextField3Value(event.target.value);
    };


    const handleClick = () => {
        setLoading(true)
        const data = {
            title: {
                bn: textField1Value,
                en: textField2Value
            },
            link: textField3Value
        }
        axios
            .post(`${server}/news/create`, data)
            .then((response) => {
                toast.success("Successfully Added.");
                setTextField1Value('')
                setTextField2Value('')
                setTextField3Value('')
                setLoading(false);
            })
            .catch((error) => {
                console.error("Error creating post:", error);
                setLoading(false);
            });

    };
    const handleDelete = (id) => {
        axios
            .delete(`${server}/news/delete-news/${id}`)
            .then((response) => {
                toast.success("Successfully Deleted.");
            })
            .catch((error) => {
                console.error("Error: ", error);
            });
    };
    useEffect(() => {
        axios
            .get(`${server}/news/get-news`)
            .then((response) => {
                setData(response.data);
            })
            .catch((error) => {
                console.error("Error: ", error);
            });
    }, [handleDelete]);


    return (
        <Box m="1.5rem 2.5rem">
            <Header title="NEWS" subTitle="List of News" />
            <Box>
                <Box sx={{ marginTop: 3 }}>
                    <Typography sx={{ marginBottom: 1 }}>News Title</Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6} md={6}>
                            <TextField
                                label="বাংলাতে লিখুন"
                                variant="outlined"
                                fullWidth
                                value={textField1Value}
                                onChange={handleTextField1Change}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                            <TextField
                                label="Enter the text in English"
                                variant="outlined"
                                fullWidth
                                value={textField2Value}
                                onChange={handleTextField2Change}
                            />
                        </Grid>
                    </Grid>
                </Box>

                <Box sx={{ marginTop: 4 }}>
                    <Typography variant="subtitle1" sx={{ paddingBottom: 2 }}>
                        Enter the link of PDF
                    </Typography>
                   
                    <Grid item xs={12} sm={6} md={6}>
                            <TextField
                                label="Enter the text in English"
                                variant="outlined"
                                fullWidth
                                value={textField3Value}
                                onChange={handleTextField3Change}
                            />
                        </Grid>
                </Box>
                <Button
                    sx={{ marginTop: 3 }}
                    onClick={handleClick}
                    variant="contained"
                    startIcon={<Send />}
                >
                    {loading ? "Posting..." : "Post"}
                </Button>
            </Box>
            <ShowNews newsData={data.news} handleDelete={handleDelete} />

        </Box>
    );
};

export default News;
