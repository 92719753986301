import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { server } from "../../server";
import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import Header from "components/Header";
import { Send } from "@mui/icons-material";
import toast from "react-hot-toast";
const UpdateJournal = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [journalData, setJournalData] = useState({});
  useEffect(() => {
    const getStaff = async () => {
      console.log(id);
      try {
        const { data } = await axios.get(
          `${server}/journal/get-single-journal`,
          {
            params: {
              _id: id,
            },
          }
        );
        setJournalData({
          _id: data.journal._id,

          titleBn: data.journal.title.bn,
          titleEn: data.journal.title.en,
          link: data.journal.link,
        });
      } catch (error) {
        console.error(error);
      }
    };
    getStaff();
  }, [id]);
  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    if (
      journalData.titleBn === "" ||
      journalData.titleEn === "" ||
      journalData.link === ""
    ) {
      toast.error("All Fields are Required.");
      setLoading(false);
    } else {
      const data = {
        _id: journalData._id,

        title: {
          bn: journalData.titleBn,
          en: journalData.titleEn,
        },
        link: journalData.link,
      };

      axios
        .put(`${server}/journal/edit-journal`, data)
        .then((response) => {
          toast.success("Successfully Updated.");
          console.log(response);
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error :", error);
          setLoading(false);
        });
    }
  };

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setJournalData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  return (
    <Box m="1.5rem 2.5rem">
      <Header title="Journal" subTitle="List of Journal" />
      <Box>
        {journalData._id && (
            <form onSubmit={handleSubmit}>
            <Box sx={{ marginTop: 3 }}>
              <Typography sx={{ marginBottom: 1 }}>Journal Title</Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={6}>
                  <TextField
                    label="বাংলাতে লিখুন"
                    variant="outlined"
                    fullWidth
                    name="titleBn"
                    value={journalData?.titleBn}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <TextField
                    label="Enter the text in English"
                    variant="outlined"
                    fullWidth
                    name="titleEn"
                    value={journalData?.titleEn}
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>
            </Box>
  
            <Box sx={{ marginTop: 4 }}>
              <Typography variant="subtitle1" sx={{ paddingBottom: 2 }}>
                Enter the link of PDF
              </Typography>
  
              <Grid item xs={12} sm={6} md={6}>
                <TextField
                  label="Enter the PDF link"
                  variant="outlined"
                  fullWidth
                  name="link"
                  value={journalData?.link}
                  onChange={handleChange}
                />
              </Grid>
            </Box>
            <Button
              sx={{ marginTop: 3 }}
              type="submit"
              variant="contained"
              startIcon={<Send />}
            >
              {loading ? "Posting..." : "Post"}
            </Button>
          </form>
        )}
        
      </Box>
    </Box>
  );
};

export default UpdateJournal;
