import React from "react";
import {
  Box,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  useTheme,
} from "@mui/material";
import {
  ChevronLeft,
  ChevronRightOutlined,
  HomeOutlined,
  ViewCarousel,
  Contacts,
  Payment,
  WorkspacePremium,
  TipsAndUpdates,
  Science,
  Article,
  Notifications,
  Collections,
  OndemandVideo,
  Group,
  ViewSidebar,
  Engineering,
  Newspaper,
} from "@mui/icons-material";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import FlexBetween from "./FlexBetween";
import logo from "../assets/logo.png"

const navItems = [
  {
    text: "Dashboard",
    icon: <HomeOutlined />,
  },
  {
    text: "Utilities",
    icon: null,
  },

  {
    text: "Banner",
    icon: <ViewCarousel />,
  },

  // {
  //   text: "Users",
  //   icon: <Group />,
  // },
  {
    text: "SideBar",
    icon: <ViewSidebar />,
  },
  {
    text: "News",
    icon: <Newspaper />,
  },
  {
    text: "Navbar",
    icon: null,
  },
  {
    text: "Staff Info",
    icon: <Engineering />,
  },
  {
    text: "NOC",
    icon: <WorkspacePremium />,
  },
  {
    text: "Contact Us",
    icon: <Contacts />,
  },

  {
    text: "Investigation Price",
    icon: <Payment />,
  },
  // {
  //   text: "ICT",
  //   icon: <TipsAndUpdates />,
  // },
  {
    text: "Research",
    icon: <Science />,
  },
  {
    text: "Journal",
    icon: <Article />,
  },
  {
    text: "Notice",
    icon: <Notifications />,
  },
  {
    text: "Gallery",
    icon: null,
  },
  {
    text: "Photo Gallery",
    icon: <Collections />,
  },
  {
    text: "Video Gallery",
    icon: <OndemandVideo />,
  },
];

const Sidebar = ({
  user,
  drawerWidth,
  isSidebarOpen,
  setIsSidebarOpen,
  isNonMobile,
}) => {
  const { pathname } = useLocation();
  const [active, setActive] = useState("");
  const navigate = useNavigate();
  const theme = useTheme();

  useEffect(() => {
    setActive(pathname.substring(1));
  }, [pathname]);

  return (
    <Box component="nav">
      {isSidebarOpen && (
        <Drawer
          open={isSidebarOpen}
          onClose={() => setIsSidebarOpen(false)}
          variant="persistent"
          anchor="left"
          sx={{
            width: drawerWidth,
            "& .MuiDrawer-paper": {
              color: theme.palette.secondary[200],
              backgroundColor: theme.palette.background.alt,
              boxSixing: "border-box",
              borderWidth: isNonMobile ? 0 : "2px",
              width: drawerWidth,
            },
          }}
        >
          <Box width="100%">
            <Box m="1.5rem 2rem 2rem 3rem">
              <FlexBetween color={theme.palette.secondary.main}>
                <Box display="flex" alignItems="center" gap="0.5rem">
                  <img style={{ width: '65px', height: '70px' }} src={logo} alt="" />
                  <Typography variant="h4" fontWeight="bold">
                    NILMRC
                  </Typography>
                </Box>
                {!isNonMobile && (
                  <IconButton onClick={() => setIsSidebarOpen(!isSidebarOpen)}>
                    <ChevronLeft />
                  </IconButton>
                )}
              </FlexBetween>
            </Box>
            <List>
              {navItems.map(({ text, icon }) => {
                if (!icon) {
                  return (
                    <Typography key={text} sx={{ m: "2.25rem 0 1rem 3rem" }}>
                      {text}
                    </Typography>
                  );
                }
                const lcText = text.toLowerCase().replaceAll(" ", "_");
                // console.log(lcText)

                return (
                  <ListItem key={text} disablePadding>
                    <ListItemButton
                      onClick={() => {
                        navigate(`/${lcText}`);
                        setActive(lcText);
                      }}
                      sx={{
                        backgroundColor:
                          active === lcText
                            ? theme.palette.secondary[300]
                            : "transparent",
                        color:
                          active === lcText
                            ? theme.palette.primary[600]
                            : theme.palette.secondary[100],
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          ml: "2rem",
                          color:
                            active === lcText
                              ? theme.palette.primary[600]
                              : theme.palette.secondary[200],
                        }}
                      >
                        {icon}
                      </ListItemIcon>
                      <ListItemText primary={text} />
                      {active === lcText && (
                        <ChevronRightOutlined sx={{ ml: "auto" }} />
                      )}
                    </ListItemButton>
                  </ListItem>
                );
              })}
            </List>
          </Box>
        </Drawer>
      )}
    </Box>
  );
};

export default Sidebar;
