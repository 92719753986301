import { Send } from "@mui/icons-material";
import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import axios from "axios";
import Header from "components/Header";
import { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import "react-quill/dist/quill.snow.css";
import { server } from "../../server";
import ShowJournal from "./ShowJournal";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "1px solid #000",
  boxShadow: 24,
  p: 3,
  borderRadius: 3,
};

const Journal = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [textField1Value, setTextField1Value] = useState("");
  const [textField2Value, setTextField2Value] = useState("");
  const [textField3Value, setTextField3Value] = useState("");

  const handleTextField1Change = (event) => {
    setTextField1Value(event.target.value);
  };

  const handleTextField2Change = (event) => {
    setTextField2Value(event.target.value);
  };
  const handleTextField3Change = (event) => {
    setTextField3Value(event.target.value);
  };

  const handleClick = () => {
    setLoading(true);
    const data = {
      title: {
        bn: textField1Value,
        en: textField2Value,
      },
      link: textField3Value,
    };
    axios
      .post(`${server}/journal/create`, data)
      .then((response) => {
        toast.success("Successfully Added.");
        setTextField1Value("");
        setTextField2Value("");
        setTextField3Value("");
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error creating post:", error);
        setLoading(false);
      });
  };
  const handleDelete = (id) => {
    axios
      .delete(`${server}/journal/delete-journal/${id}`)
      .then((response) => {
        toast.success("Successfully Deleted.");
        window.location.reload();
      })
      .catch((error) => {
        console.error("Error: ", error);
      });
  };
  useEffect(() => {
    axios
      .get(`${server}/journal/get-journal`)
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => {
        console.error("Error: ", error);
      });
  }, []);

  return (
    <Box m="1.5rem 2.5rem">
      <Header title="Journal" subTitle="List of Journal" />
      <Box>
        <Box sx={{ marginTop: 3 }}>
          <Typography sx={{ marginBottom: 1 }}>Journal Title</Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={6}>
              <TextField
                label="বাংলাতে লিখুন"
                variant="outlined"
                fullWidth
                value={textField1Value}
                onChange={handleTextField1Change}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <TextField
                label="Enter the text in English"
                variant="outlined"
                fullWidth
                value={textField2Value}
                onChange={handleTextField2Change}
              />
            </Grid>
          </Grid>
        </Box>

        <Box sx={{ marginTop: 4 }}>
          <Typography variant="subtitle1" sx={{ paddingBottom: 2 }}>
            Enter the link of PDF
          </Typography>

          <Grid item xs={12} sm={6} md={6}>
            <TextField
              label="Enter the PDF link"
              variant="outlined"
              fullWidth
              value={textField3Value}
              onChange={handleTextField3Change}
            />
          </Grid>
        </Box>
        <Button
          sx={{ marginTop: 3 }}
          onClick={handleClick}
          variant="contained"
          startIcon={<Send />}
        >
          {loading ? "Posting..." : "Post"}
        </Button>
      </Box>
      <ShowJournal
        journalData={data?.journal || []}
        handleDelete={handleDelete}
      />
    </Box>
  );
};

export default Journal;
