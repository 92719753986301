import React from "react";
import { Box, Button, TextField } from "@mui/material";
import Header from "components/Header";
import BreakdownChart from "components/BreakdownChart";
import { CloudUploadRounded } from "@mui/icons-material";

const Breakdown = () => {
  return <Box m="1.5rem 2.5rem">
    <Header title="INVESTIGATION PRICE" subTitle="Add link" />
    <Box sx={{ marginTop: 2 }}>
      <TextField
        label="Enter the link of PDF here"
        variant="outlined"
        fullWidth

      />
      <Button
        sx={{ marginTop: 2 }}
        variant="contained"
        startIcon={<CloudUploadRounded />}
      >
        Update
      </Button>
    </Box>

  </Box>;
};

export default Breakdown;
