import { PersonAdd, Photo } from "@mui/icons-material";
import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import Header from "components/Header";
import { useEffect, useState } from "react";
import { server } from "../../server";
import axios from "axios";
import { toast } from "react-hot-toast";
import ShowSideBar from "./ShowSideBar";

const SideBar = () => {
  const [avatar, setAvatar] = useState("");
  const [loading, setLoading] = useState(false);
  const [bar, setBar] = useState("");
  const [data, setData] = useState([]);
const [deleteData,setDeleteData]=useState(0)
  const handleBarChange = (event) => {
    setBar(event.target.value);
  };

  const [formData, setFormData] = useState({
    serial: 0,
    designationBn: "",
    designationEn: "",
    nameBn: "",
    nameEn: "",
    institutionBn: "",
    institutionEn: "",
    addressBn: "",
    addressEn: "",
    phoneNumBn: "",
    phoneNumEn: "",
    roomNumBn: "",
    roomNumEn: "",
    desBn: "",
    desEn: "",
    email: "",
  });

  const handleAvatarChange = (e) => {
    const reader = new FileReader();

    reader.onload = () => {
      if (reader.readyState === 2) {
        setAvatar(reader.result);
      }
    };

    reader.readAsDataURL(e.target.files[0]);
  };

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleClick = () => {
    setLoading(true);

    if (
      formData.serial === 0 ||
      formData.designationBn === "" ||
      formData.designationEn === "" ||
      formData.nameBn === "" ||
      formData.nameEn === "" ||
      formData.institutionBn === "" ||
      formData.institutionEn === "" ||
      formData.addressBn === "" ||
      formData.addressEn === "" ||
      formData.phoneNumBn === "" ||
      formData.phoneNumEn === "" ||
      formData.roomNumBn === "" ||
      formData.roomNumEn === "" ||
      formData.email === "" ||
      formData.desBn === "" ||
      formData.desEn === "" ||
      avatar === "" ||
      bar === ""
    ) {
      toast.error("All Fields are Required.");
      setLoading(false);
    } else {
      const data = {
        designation: {
          bn: formData.designationBn,
          en: formData.designationEn,
        },
        name: {
          bn: formData.nameBn,
          en: formData.nameEn,
        },
        institution: {
          bn: formData.institutionBn,
          en: formData.institutionEn,
        },
        address: {
          bn: formData.addressBn,
          en: formData.addressEn,
        },
        phoneNumber: {
          bn: formData.phoneNumBn,
          en: formData.phoneNumEn,
        },
        roomNumber: {
          bn: formData.roomNumBn,
          en: formData.roomNumEn,
        },
        description: {
          bn: formData.desBn,
          en: formData.desEn,
        },
        email: formData.email,
        avatar,
        side: bar,
        serial: formData.serial,
      };

      axios
        .post(`${server}/sidebar/create`, data)
        .then((response) => {
          toast.success("Successfully Added.");
          setFormData({
            designationBn: "",
            designationEn: "",
            nameBn: "",
            nameEn: "",
            institutionBn: "",
            institutionEn: "",
            addressBn: "",
            addressEn: "",
            phoneNumBn: "",
            phoneNumEn: "",
            roomNumBn: "",
            roomNumEn: "",
            desBn: "",
            desEn: "",
            email: "",
            serial: 0,
          });
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error creating post:", error);
          setLoading(false);
        });
    }
  };

  const handleDelete = (id) => {
    axios
      .delete(`${server}/sidebar/delete-sidebar/${id}`)
      .then((response) => {
        toast.success("Successfully Deleted.");
        setDeleteData(deleteData+1)
      })
      .catch((error) => {
        console.error("Error: ", error);
      });
  };

  useEffect(() => {
    axios
      .get(`${server}/sidebar/get-sidebar`)
      .then((response) => {
        setData(response.data);
       
      })
      .catch((error) => {
        console.error("Error: ", error);
      });
  }, [loading,deleteData]);

  return (
    <Box m="1.5rem 2.5rem">
      <Header title="SIDE NAVIGATION" subTitle="Add Information for people" />
      <form>
        <Box sx={{ marginTop: 3 }}>
          <Box sx={{ minWidth: 120, marginTop: 3, marginBottom: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={6}>
                <Typography sx={{ marginBottom: 1 }}>Side</Typography>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Side</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={bar}
                    label="Side"
                    onChange={handleBarChange}
                  >
                    <MenuItem value="Right">Left</MenuItem>
                    <MenuItem value="Left">Right</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <Typography sx={{ marginBottom: 1 }}>Serial No.</Typography>
                <TextField
                  label="Place at (in English)"
                  variant="outlined"
                  type="number"
                  fullWidth
                  name="serial"
                  value={formData.serial}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
          </Box>
          <Box>
            <Typography sx={{ marginBottom: 1 }}>পদবী/Designation</Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={6}>
                <TextField
                  label="বাংলাতে লিখুন"
                  variant="outlined"
                  fullWidth
                  name="designationBn"
                  value={formData.designationBn}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <TextField
                  label="Enter the text in English"
                  variant="outlined"
                  fullWidth
                  name="designationEn"
                  value={formData.designationEn}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
          </Box>
          <Box sx={{ marginTop: 3 }}>
            <Typography sx={{ marginBottom: 1 }}>নাম/Name</Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={6}>
                <TextField
                  label="বাংলাতে লিখুন"
                  variant="outlined"
                  fullWidth
                  name="nameBn"
                  value={formData.nameBn}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <TextField
                  label="Enter the text in English"
                  variant="outlined"
                  fullWidth
                  name="nameEn"
                  value={formData.nameEn}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
          </Box>
          <Box sx={{ marginTop: 3 }}>
            <Typography sx={{ marginBottom: 1 }}>
              প্রতিষ্ঠান/Institution
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={6}>
                <TextField
                  label="বাংলাতে লিখুন"
                  variant="outlined"
                  fullWidth
                  name="institutionBn"
                  value={formData.institutionBn}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <TextField
                  label="Enter the text in English"
                  variant="outlined"
                  fullWidth
                  name="institutionEn"
                  value={formData.institutionEn}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
          </Box>
          <Box sx={{ marginTop: 3 }}>
            <Typography sx={{ marginBottom: 1 }}>ঠিকানা/Address</Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={6}>
                <TextField
                  label="বাংলাতে লিখুন"
                  variant="outlined"
                  fullWidth
                  name="addressBn"
                  value={formData.addressBn}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <TextField
                  label="Enter the text in English"
                  variant="outlined"
                  fullWidth
                  name="addressEn"
                  value={formData.addressEn}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
          </Box>
          <Box sx={{ marginTop: 3 }}>
            <Typography sx={{ marginBottom: 1 }}>
              ফোন নম্বর/Phone Number
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={6}>
                <TextField
                  label="বাংলাতে লিখুন"
                  variant="outlined"
                  fullWidth
                  name="phoneNumBn"
                  value={formData.phoneNumBn}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <TextField
                  label="Enter the text in English"
                  variant="outlined"
                  fullWidth
                  name="phoneNumEn"
                  value={formData.phoneNumEn}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
          </Box>
          <Box sx={{ marginTop: 3 }}>
            <Typography sx={{ marginBottom: 1 }}>
              রুম নম্বর/Room Number
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={6}>
                <TextField
                  label="বাংলাতে লিখুন"
                  variant="outlined"
                  fullWidth
                  name="roomNumBn"
                  value={formData.roomNumBn}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <TextField
                  label="Enter the text in English"
                  variant="outlined"
                  fullWidth
                  name="roomNumEn"
                  value={formData.roomNumEn}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
          </Box>
          <Box sx={{ marginTop: 3 }}>
            <Typography sx={{ marginBottom: 1 }}>বিবরণ/Description</Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={6}>
                <TextField
                  label="বাংলাতে লিখুন"
                  variant="outlined"
                  fullWidth
                  name="desBn"
                  value={formData.desBn}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <TextField
                  label="Enter the text in English"
                  variant="outlined"
                  fullWidth
                  name="desEn"
                  value={formData.desEn}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
          </Box>
          <Box sx={{ marginTop: 3, marginBottom: 3 }}>
            <Typography sx={{ marginBottom: 1 }}>ই-মেইল/E-mail</Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={6}>
                <TextField
                  label="Enter the text in English"
                  variant="outlined"
                  fullWidth
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <Button
                  variant="contained"
                  component="label"
                  fullWidth
                  sx={{ height: 52.7 }}
                  startIcon={<Photo />}
                  name="photo"
                  value={formData.photo}
                  onChange={handleAvatarChange}
                >
                  <input type="file" />
                </Button>
              </Grid>
            </Grid>
          </Box>

          <Button
            onClick={handleClick}
            sx={{ marginTop: 2 }}
            variant="contained"
            startIcon={<PersonAdd />}
          >
            {loading ? "Adding..." : "Add People"}
          </Button>
        </Box>
      </form>
      <ShowSideBar sidebarData={data.sidebar} handleDelete={handleDelete} />
    </Box>
  );
};

export default SideBar;
