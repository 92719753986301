import React, { useEffect, useState } from "react";
import { PersonAdd, Photo } from "@mui/icons-material";
import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import Header from "components/Header";
import axios from "axios";
import { server } from "../../server";
import { useParams } from "react-router-dom";
import toast from "react-hot-toast";
const UpdateSideBar = () => {
  const { id } = useParams();
  const [avatar, setAvatar] = useState("");
  const [loading, setLoading] = useState(false);
  const [bar, setBar] = useState("");
  const [data, setData] = useState([]);
  const [formData, setFormData] = useState({
    serial: 0,
    designationBn: "",
    designationEn: "",
    nameBn: "",
    nameEn: "",
    institutionBn: "",
    institutionEn: "",
    addressBn: "",
    addressEn: "",
    phoneNumBn: "",
    phoneNumEn: "",
    roomNumBn: "",
    roomNumEn: "",
    desBn: "",
    desEn: "",
    email: "",
  });
  const handleBarChange = (event) => {
    setBar(event.target.value);
  };
  const handleAvatarChange = (e) => {
    const reader = new FileReader();

    reader.onload = () => {
      if (reader.readyState === 2) {
        setAvatar({ ...avatar, url: reader.result });
      }
    };
    reader.readAsDataURL(e.target.files[0]);
    setFormData((prevData) => ({
      ...prevData,
      avatarChanged: true,
    }));
  };

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  useEffect(() => {
    axios
      .get(`${server}/sidebar/get-single-sidebar`, {
        params: {
          _id: id,
        },
      })
      .then((response) => {
        console.log(response.data.sidebar, "checkdata");
        console.log(response.data.sidebar._id, "checkdata1");
        setFormData({
          _id: response.data.sidebar._id,
          serial: response.data.sidebar.serial,
          designationBn: response.data.sidebar.designation.bn,
          designationEn: response.data.sidebar.designation.en,
          nameBn: response.data.sidebar.name.bn,
          nameEn: response.data.sidebar.name.en,
          institutionBn: response.data.sidebar.institution.bn,
          institutionEn: response.data.sidebar.institution.bn,
          addressBn: response.data.sidebar.address.bn,
          addressEn: response.data.sidebar.address.bn,
          phoneNumBn: response.data.sidebar.phoneNumber.bn,
          phoneNumEn: response.data.sidebar.phoneNumber.en,
          roomNumBn: response.data.sidebar.roomNumber.bn,
          roomNumEn: response.data.sidebar.roomNumber.en,
          desBn: response.data.sidebar.description.bn,
          desEn: response.data.sidebar.description.en,
          email: response.data.sidebar.email,
        });
        setAvatar(response.data.sidebar.avatar);
        setBar(response.data.sidebar.side);
        console.log(formData, "afl");
      })
      .catch((error) => {
        console.error("Error: ", error);
      });
  }, []);
  const handleSubmit = (e) => {
    e.preventDefault();
    // console.log("aaa", galleryData);
    setLoading(true);

    if (
      formData.serial === "" ||
      formData.designationBn === "" ||
      formData.designationEn === "" ||
      formData.nameBn === "" ||
      formData.nameEn === "" ||
      formData.institutionBn === "" ||
      formData.institutionEn === "" ||
      formData.addressBn === "" ||
      formData.addressEn === "" ||
      formData.phoneNumBn === "" ||
      formData.phoneNumEn === "" ||
      formData.roomNumBn === "" ||
      formData.roomNumEn === "" ||
      formData.desBn === "" ||
      formData.desEn === "" ||
      formData.email === ""
    ) {
      toast.error("All Fields are Required.");
      setLoading(false);
    } else {
      const data = {
        _id: formData._id,

        designation: {
          en: formData.designationEn,
          bn: formData.designationBn,
        },
        name: {
          en: formData.nameEn,
          bn: formData.nameBn,
        },
        institution: {
          en: formData.institutionEn,
          bn: formData.institutionBn,
        },
        address: {
          en: formData.addressEn,
          bn: formData.addressBn,
        },
        phoneNumber: {
          en: formData.phoneNumEn,
          bn: formData.phoneNumBn,
        },
        description: {
          en: formData.desEn,
          bn: formData.desBn,
        },
        roomNumber: {
          en: formData.roomNumEn,
          bn: formData.roomNumBn,
        },
        side: bar,
        serial: formData.serial,
        email: formData.email,
        avatar,
        avatarChanged: formData.avatarChanged ? true : false,
      };
      console.log("dataCheck", data);
      axios
        .put(`${server}/sidebar/edit`, data)
        .then((response) => {
          toast.success("Successfully Updated.");
          console.log(response);
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error :", error);
          setLoading(false);
        });
    }
  };
  console.log(formData, "akl");
  return (
    <Box m="1.5rem 2.5rem">
      <Header title="SIDE NAVIGATION" subTitle="Add Information for people" />
      {formData?._id && (
        <form onSubmit={handleSubmit}>
          <Box sx={{ marginTop: 3 }}>
            <Box sx={{ minWidth: 120, marginTop: 3, marginBottom: 3 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={6}>
                  <Typography sx={{ marginBottom: 1 }}>Side</Typography>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Side</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={bar}
                      label="Side"
                      onChange={handleBarChange}
                    >
                      <MenuItem value="Right">Left</MenuItem>
                      <MenuItem value="Left">Right</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <Typography sx={{ marginBottom: 1 }}>Serial No.</Typography>
                  <TextField
                    label="Place at (in English)"
                    variant="outlined"
                    type="number"
                    fullWidth
                    name="serial"
                    value={formData.serial}
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>
            </Box>
            <Box>
              <Typography sx={{ marginBottom: 1 }}>পদবী/Designation</Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={6}>
                  <TextField
                    label="বাংলাতে লিখুন"
                    variant="outlined"
                    fullWidth
                    name="designationBn"
                    value={formData.designationBn}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <TextField
                    label="Enter the text in English"
                    variant="outlined"
                    fullWidth
                    name="designationEn"
                    value={formData.designationEn}
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>
            </Box>
            <Box sx={{ marginTop: 3 }}>
              <Typography sx={{ marginBottom: 1 }}>নাম/Name</Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={6}>
                  <TextField
                    label="বাংলাতে লিখুন"
                    variant="outlined"
                    fullWidth
                    name="nameBn"
                    value={formData.nameBn}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <TextField
                    label="Enter the text in English"
                    variant="outlined"
                    fullWidth
                    name="nameEn"
                    value={formData.nameEn}
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>
            </Box>
            <Box sx={{ marginTop: 3 }}>
              <Typography sx={{ marginBottom: 1 }}>
                প্রতিষ্ঠান/Institution
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={6}>
                  <TextField
                    label="বাংলাতে লিখুন"
                    variant="outlined"
                    fullWidth
                    name="institutionBn"
                    value={formData.institutionBn}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <TextField
                    label="Enter the text in English"
                    variant="outlined"
                    fullWidth
                    name="institutionEn"
                    value={formData.institutionEn}
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>
            </Box>
            <Box sx={{ marginTop: 3 }}>
              <Typography sx={{ marginBottom: 1 }}>ঠিকানা/Address</Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={6}>
                  <TextField
                    label="বাংলাতে লিখুন"
                    variant="outlined"
                    fullWidth
                    name="addressBn"
                    value={formData.addressBn}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <TextField
                    label="Enter the text in English"
                    variant="outlined"
                    fullWidth
                    name="addressEn"
                    value={formData.addressEn}
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>
            </Box>
            <Box sx={{ marginTop: 3 }}>
              <Typography sx={{ marginBottom: 1 }}>
                ফোন নম্বর/Phone Number
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={6}>
                  <TextField
                    label="বাংলাতে লিখুন"
                    variant="outlined"
                    fullWidth
                    name="phoneNumBn"
                    value={formData.phoneNumBn}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <TextField
                    label="Enter the text in English"
                    variant="outlined"
                    fullWidth
                    name="phoneNumEn"
                    value={formData.phoneNumEn}
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>
            </Box>
            <Box sx={{ marginTop: 3 }}>
              <Typography sx={{ marginBottom: 1 }}>
                রুম নম্বর/Room Number
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={6}>
                  <TextField
                    label="বাংলাতে লিখুন"
                    variant="outlined"
                    fullWidth
                    name="roomNumBn"
                    value={formData.roomNumBn}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <TextField
                    label="Enter the text in English"
                    variant="outlined"
                    fullWidth
                    name="roomNumEn"
                    value={formData.roomNumEn}
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>
            </Box>
            <Box sx={{ marginTop: 3 }}>
              <Typography sx={{ marginBottom: 1 }}>
                বিবরণ/Description
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={6}>
                  <TextField
                    label="বাংলাতে লিখুন"
                    variant="outlined"
                    fullWidth
                    name="desBn"
                    value={formData.desBn}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <TextField
                    label="Enter the text in English"
                    variant="outlined"
                    fullWidth
                    name="desEn"
                    value={formData.desEn}
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>
            </Box>
            <Box sx={{ marginTop: 3, marginBottom: 3 }}>
              <Typography sx={{ marginBottom: 1 }}>ই-মেইল/E-mail</Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={6}>
                  <TextField
                    label="Enter the text in English"
                    variant="outlined"
                    fullWidth
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <Button
                    variant="contained"
                    component="label"
                    fullWidth
                    sx={{ height: 52.7 }}
                    startIcon={<Photo />}
                    name="photo"
                    value={formData.photo}
                    onChange={handleAvatarChange}
                  >
                    <input type="file" />
                  </Button>
                  <div>
                    <img
                      src={avatar.url}
                      alt="avatar"
                      style={{ width: "120px", height: "120px" }}
                    />
                  </div>
                </Grid>
              </Grid>
            </Box>

            <Button
              type="submit"
              sx={{ marginTop: 2 }}
              variant="contained"
              startIcon={<PersonAdd />}
            >
              {loading ? "Updating..." : "Update People"}
            </Button>
          </Box>
        </form>
      )}
    </Box>
  );
};

export default UpdateSideBar;
