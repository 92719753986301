import React, { useEffect, useState } from "react";
import {
    Box,
    Button,
    Grid,
    IconButton,
    TextField,
    Typography,
    useTheme,
} from "@mui/material";
import Header from "components/Header";
import { useGetAdminsQuery, useGetCustomersQuery } from "state/api";
import axios from "axios";
import { server } from "server";
import { toast } from "react-hot-toast";
import { CloudUploadRounded, Delete, Edit, Photo } from "@mui/icons-material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DeleteIcon from "@mui/icons-material/Delete";
import ShowVideoGallery from "./showVideoGallery";

const VideoGallery = () => {
    const theme = useTheme();
    const { isLoading } = useGetCustomersQuery();
    const [data, setData] = useState([]);
    const [images, setImages] = useState([]);
    const [textField1Value, setTextField1Value] = useState("");
    const [textField2Value, setTextField2Value] = useState("");
    const [textField3Value, setTextField3Value] = useState("");
    const [textField4Value, setTextField4Value] = useState("");
    const [textField5Value, setTextField5Value] = useState("");
    const [thumbnail, setThumbnail] = useState(null);
    const [loading, setLoading] = useState(false);

    const handleTextField1Change = (event) => {
        setTextField1Value(event.target.value);
    };

    const handleTextField2Change = (event) => {
        setTextField2Value(event.target.value);
    };
    const handleTextField3Change = (event) => {
        setTextField3Value(event.target.value);
    };

    const handleTextField4Change = (event) => {
        setTextField4Value(event.target.value);
    };
    const handleTextField5Change = (event) => {
        setTextField5Value(event.target.value);
    };

    const handleThumbnailChange = (e) => {
        const reader = new FileReader();

        reader.onload = () => {
            if (reader.readyState === 2) {
                setThumbnail(reader.result);
            }
        };

        reader.readAsDataURL(e.target.files[0]);
    };

    const handleClick = () => {
        setLoading(true);

        const data = {
            title: {
                bn: textField1Value,
                en: textField2Value,
            },
            description: {
                bn: textField3Value,
                en: textField4Value,
            },
            thumbnail,
            link:textField5Value

        };

        console.log(data)

        axios
            .post(`${server}/videoGallery/create`, data)
            .then((response) => {
                toast.success("Successfully Added.");
                setTextField1Value("");
                setTextField2Value("");
                setTextField3Value("");
                setTextField4Value("");
                setTextField5Value("");
                setThumbnail();
                window.location.reload()
                setLoading(false);
            })
            .catch((error) => {
                console.error("Error creating post:", error);
                setLoading(false);
            });
    };

    const handleDelete = (id) => {
        axios
            .delete(`${server}/videoGallery/delete-video_gallery/${id}`)
            .then((response) => {
                toast.success("Successfully Deleted.");
            })
            .catch((error) => {
                console.error("Error: ", error);
            });
    };

    useEffect(() => {
        axios
            .get(`${server}/videoGallery/get-video_gallery`)
            .then((response) => {
                setData(response.data);
            })
            .catch((error) => {
                console.error("Error creating post:", error);
            });
    }, [
        
        
    ]);

    return (
        <Box m="1.5rem 2.5rem">
            <Header title="VIDEO GALLERY" subTitle="Managing video gallery" />
            <Box
                sx={{
                    marginTop: 2,
                }}
            >
                <Typography
                    sx={{
                        marginBottom: 1,
                    }}
                >
                    ENTER THE TITLE
                </Typography>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={6}>
                        <TextField
                            label="বাংলাতে লিখুন"
                            variant="outlined"
                            fullWidth
                            value={textField1Value}
                            onChange={handleTextField1Change}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                        <TextField
                            label="Write in English"
                            variant="outlined"
                            fullWidth
                            value={textField2Value}
                            onChange={handleTextField2Change}
                        />
                    </Grid>
                </Grid>
                <Typography
                    sx={{
                        marginBottom: 1,
                        marginTop: 3,
                    }}
                >
                    ENTER THE DESCRIPTION
                </Typography>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={6}>
                        <TextField
                            label="বাংলাতে লিখুন"
                            variant="outlined"
                            fullWidth
                            value={textField3Value}
                            onChange={handleTextField3Change}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                        <TextField
                            label="Write in English"
                            variant="outlined"
                            fullWidth
                            value={textField4Value}
                            onChange={handleTextField4Change}
                        />
                    </Grid>
                </Grid>
                <Typography
                    sx={{
                        marginBottom: 1,
                        marginTop: 3,
                    }}
                >
                    CHOOSE A THUMBNAIL PHOTO
                </Typography>
                <Button
                    variant="contained"
                    component="label"
                    sx={{}}
                    startIcon={<Photo />}
                >
                    <input onChange={handleThumbnailChange} type="file" />
                </Button>
                <Typography
                    sx={{
                        marginBottom: 1,
                        marginTop: 3,
                    }}
                >
                    ADD A VIDEO LINK
                </Typography>
                <Grid>
                    <TextField
                        label="Video link"
                        variant="outlined"
                        fullWidth
                        value={textField5Value}
                        onChange={handleTextField5Change}
                    />
                </Grid>
                <Button
                    sx={{ marginTop: 3 }}
                    onClick={handleClick}
                    variant="contained"
                    disabled={loading}
                    startIcon={<CloudUploadRounded />}
                >
                    {loading ? "Uploading..." : "Update"}
                </Button>
            </Box>

            <ShowVideoGallery videoGalleryData={data.videoGallery} handleDelete={handleDelete} />
        </Box>
    );
};

export default VideoGallery;
