import React, { useState } from "react";
import {
  Box,
  Button,
  FormControl,
  Input,
  InputLabel,
  Modal,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import Header from "components/Header";
import { useGetProductsQuery } from "state/api";
import { Delete, Edit, Person, PersonAdd, Photo } from "@mui/icons-material";
import { DataGrid } from "@mui/x-data-grid";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "1px solid #000",
  boxShadow: 24,
  p: 3,
  borderRadius: 3,
};

const columns = [
  { field: "id", headerName: "ID", width: 90 },
  {
    field: "fullName",
    headerName: "Full name",
    width: 250,
    editable: true,
  },
  {
    field: "userName",
    headerName: "Username",
    width: 250,
    editable: true,
  },
  {
    field: "phone",
    headerName: "Phone number",

    width: 250,
    editable: true,
  },
  {
    field: "email",
    headerName: "E-mail address",
    width: 250,
    editable: true,
  },
  {
    field: "action",
    headerName: "Action",
    width: 250,
    editable: true,
  },
];

const rows = [];

const Users = () => {
  const [open, setOpen] = React.useState(false);
  const [data, setData] = useState(rows);

  const handleClose = () => setOpen(!open);
  const isNonMobile = useMediaQuery("(min-width: 1000px)");

  const [formData, setFormData] = useState({
    id: 0,
    fullName: "",
    email: "",
    userName: "",
    phone: "",
    password: "",
  });


  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    formData.id = Math.floor(Math.random() * (100 - 1 + 1)) + 1;
    handleClose();
    setData([...data, formData]);
    setFormData({});
  };

  return (
    <Box m="1.5rem 2.5rem">
      <Header title="USERS" subTitle="Users List" />
      <Box>
        <div>
          <Box sx={{ position: "absolute", right: 40 }}>
            <Tooltip title="Add User">
              <Button
                onClick={handleClose}
                variant="contained"
                sx={{ height: "52px" }}
                size="large"
                startIcon={<PersonAdd />}
              >
                Add User{" "}
              </Button>
            </Tooltip>
          </Box>

          <div>
            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                <Typography id="modal-modal-title" variant="h6">
                  Enter the informations
                </Typography>
                <form onSubmit={handleSubmit}>
                  <TextField
                    id="outlined-multiline-flexible"
                    label="Enter the name"
                    multiline
                    maxRows={4}
                    fullWidth
                    name="fullName"
                    value={formData.fullName}
                    onChange={handleChange}
                    sx={{ marginTop: 2, marginBottom: 2 }}
                  />
                  <TextField
                    id="outlined-multiline-flexible"
                    label="Username"
                    multiline
                    maxRows={4}
                    fullWidth
                    name="userName"
                    value={formData.userName}
                    onChange={handleChange}
                    sx={{ marginTop: 2, marginBottom: 2 }}
                  />
                  <TextField
                    id="outlined-multiline-flexible"
                    label="Phone number"
                    multiline
                    maxRows={4}
                    fullWidth
                    name="phone"
                    value={formData.phone}
                    onChange={handleChange}
                    sx={{ marginTop: 2, marginBottom: 2 }}
                  />
                  <TextField
                    id="outlined-multiline-flexible"
                    label="E-mail address"
                    multiline
                    maxRows={4}
                    fullWidth
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    sx={{ marginTop: 2, marginBottom: 2 }}
                  />
                  <TextField
                    id="outlined-multiline-flexible"
                    label="Password"
                    multiline
                    maxRows={4}
                    fullWidth
                    name="password"
                    type="password"
                    value={formData.password}
                    onChange={handleChange}
                    sx={{ marginTop: 2, marginBottom: 2 }}
                  />
                  <Button
                    variant="contained"
                    component="label"
                    fullWidth
                    sx={{ marginTop: 2, marginBottom: 2 }}
                    startIcon={<Photo />}
                  >
                    Upload Photo
                    <input type="file" hidden />
                  </Button>
                  <br />

                  <Box sx={{ display: "flex", flexDirection: "row", gap: 2 }}>
                    <Button fullWidth type="submit" variant="contained">
                      Save
                    </Button>
                    <Button fullWidth onClick={handleClose} variant="contained">
                      Close
                    </Button>
                  </Box>
                </form>
              </Box>
            </Modal>
          </div>
        </div>
      </Box>

      <Box sx={{ height: 600, width: "100%", marginTop: 10 }}>
        <DataGrid
          rows={data}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: {
                pphoneSize: 5,
              },
            },
          }}
          pphoneSizeOptions={[5]}
          disableRowSelectionOnClick
        />
      </Box>
    </Box>
  );
};

export default Users;
