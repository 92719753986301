import { CloudUploadRounded } from "@mui/icons-material";
import {
  Box,
  Button,
  Grid,
  TextField,
  Typography
} from "@mui/material";
import axios from "axios";
import Header from "components/Header";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import { convertISOToYYYYMMDD } from "utilities/convertDate";
import { server } from "../../server";

const UpdateNOC = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [NOCData, setNOCData] = useState({});
  console.log("sss", NOCData);

  useEffect(() => {
    const getStaff = async () => {
      console.log(id);
      try {
        const { data } = await axios.get(`${server}/noc/get-single-noc`, {
          params: {
            _id: id,
          },
        });
        setNOCData({
          _id: data.noc._id,
          date:convertISOToYYYYMMDD(data.noc.date),
          nameBn: data.noc.name.bn,
          nameEn: data.noc.name.en,
          link: data.noc.link,
        });
      } catch (error) {
        console.error(error);
      }
    };
    getStaff();
  }, [id]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    if (
      NOCData.nameBn === "" ||
      NOCData.nameEn === "" ||
      NOCData.link === "" ||
      NOCData.date === ""
    ) {
      toast.error("All Fields are Required.");
      setLoading(false);
    } else {
      const data = {
        _id: NOCData._id,

        name: {
          bn: NOCData.nameBn,
          en: NOCData.nameEn,
        },
        link: NOCData.link,
        date: NOCData.date,
      };

      axios
        .put(`${server}/noc/edit-noc`, data)
        .then((response) => {
          toast.success("Successfully Updated.");
          console.log(response);
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error :", error);
          setLoading(false);
        });
    }
  };

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setNOCData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  if (loading) {
    return <Box sx={{ height: "100vh", width: "100%" }}>Loading...</Box>;
  }
  return (
    <>
      <Box m="1.5rem 2.5rem">
        <Header title="NOC" subTitle="List of NOCs" />
        {NOCData?._id && (
          <form onSubmit={handleSubmit}>
            <Box sx={{ marginTop: 2 }}>
              <Typography sx={{ marginBottom: 1 }}>
                Enter the name here
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={6}>
                  <TextField
                    label="বাংলাতে লিখুন"
                    variant="outlined"
                    fullWidth
                    name="nameBn"
                    type="text"
                    value={NOCData.nameBn}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <TextField
                    label="Enter the text in English"
                    variant="outlined"
                    fullWidth
                    name="nameEn"
                    type="text"
                    value={NOCData.nameEn}
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={6} sx={{ marginTop: 3 }}>
                  <Box>
                    <Typography
                      sx={{
                        marginBottom: 1,
                      }}
                    >
                      CHOOSE A DATE
                    </Typography>

                    <TextField
                      sx={{
                        width: "100%",
                      }}
                      id="outlined-basic"
                      variant="outlined"
                      type="date"
                      name="date"
                      value={NOCData.date}
                      onChange={handleChange}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={6} sx={{ marginTop: 6.5 }}>
                  <Box>
                    <TextField
                      label="Enter the drive link of NOC"
                      variant="outlined"
                      fullWidth
                      name="link"
                      type="text"
                      value={NOCData.link}
                      onChange={handleChange}
                    />
                  </Box>
                </Grid>
              </Grid>

              <br />
              <Button
                type="submit"
                sx={{ marginTop: 1 }}
                variant="contained"
                startIcon={<CloudUploadRounded />}
              >
                Update
              </Button>
            </Box>
          </form>
        )}
      </Box>
    </>
  );
};
export default UpdateNOC;
