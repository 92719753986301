import { PersonAdd, Photo } from "@mui/icons-material";
import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography
} from "@mui/material";
import axios from "axios";
import Header from "components/Header";
import { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { server } from "../../server";
import ShowStaff from "./ShowStaff";

const Staff = () => {
  const [refetch, setRefetch] = useState(false);
  const [avatar, setAvatar] = useState("");
  const [loading, setLoading] = useState(false);
  const [bloodGroup, setBloodGroup] = useState("");
  const [data, setData] = useState([]);

  const handleBloodGroupChange = (event) => {
    setBloodGroup(event.target.value);
  };

  const [formData, setFormData] = useState({
    idNo: "",
    serial: 0,
    designationBn: "",
    designationEn: "",
    nameBn: "",
    nameEn: "",
    phoneNumBn: "",
    phoneNumEn: "",
    email: "",
  });

  const handleAvatarChange = (e) => {
    const reader = new FileReader();

    reader.onload = () => {
      if (reader.readyState === 2) {
        setAvatar(reader.result);
      }
    };

    reader.readAsDataURL(e.target.files[0]);
  };

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleClick = () => {
    setLoading(true);

    if (
      formData.serial === 0 ||
      formData.designationBn === "" ||
      formData.designationEn === "" ||
      formData.nameBn === "" ||
      formData.nameEn === "" ||
      formData.phoneNumBn === "" ||
      formData.phoneNumEn === "" ||
      formData.idNo === "" ||
      formData.email === "" ||
      avatar === "" ||
      bloodGroup === ""
    ) {
      toast.error("All Fields are Required.");
      setLoading(false);
    } else {
      const data = {
        designation: {
          bn: formData.designationBn,
          en: formData.designationEn,
        },
        name: {
          bn: formData.nameBn,
          en: formData.nameEn,
        },
        phoneNumber: {
          bn: formData.phoneNumBn,
          en: formData.phoneNumEn,
        },
        email: formData.email,
        avatar,
        serial: formData.serial,
        bloodGroup,
        idNo: `NILMRC-${String(formData.idNo).padStart(4, "0")}`,
      };

      axios
        .post(`${server}/staffInfo/create`, data)
        .then((response) => {
          toast.success("Successfully Added.");
          setFormData({
            idNo: "",
            serial: 0,
            designationBn: "",
            designationEn: "",
            nameBn: "",
            nameEn: "",
            phoneNumBn: "",
            phoneNumEn: "",
            email: "",
          });
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error :", error);
          setLoading(false);
        });
    }
  };

  const handleDelete = (id) => {
    axios
      .delete(`${server}/staffInfo/delete-staffinfo/${id}`)
      .then((response) => {
        setRefetch(!refetch);
        toast.success("Successfully Deleted.");
      })
      .catch((error) => {
        console.error("Error: ", error);
      });
  };

  useEffect(() => {
    axios
      .get(`${server}/staffInfo/get-staffinfo`)
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => {
        console.error("Error: ", error);
      });
  }, [refetch]);

  return (
    <>
      <Box m="1.5rem 2.5rem">
        <Header
          title="STAFF INFORMTION"
          subTitle="Add Information for office staff"
        />
        <form>
          <Box sx={{ marginTop: 3 }}>
            <Box sx={{ marginTop: 3 }}>
              <Typography sx={{ marginBottom: 1 }}>নাম/Name</Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={6}>
                  <TextField
                    label="বাংলাতে লিখুন"
                    variant="outlined"
                    fullWidth
                    name="nameBn"
                    value={formData.nameBn}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <TextField
                    label="Enter the text in English"
                    variant="outlined"
                    fullWidth
                    name="nameEn"
                    value={formData.nameEn}
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>
            </Box>
            <Box>
              <Typography sx={{ marginBottom: 1, marginTop: 3 }}>
                পদবী/Designation
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={6}>
                  <TextField
                    label="বাংলাতে লিখুন"
                    variant="outlined"
                    fullWidth
                    name="designationBn"
                    value={formData.designationBn}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <TextField
                    label="Enter the text in English"
                    variant="outlined"
                    fullWidth
                    name="designationEn"
                    value={formData.designationEn}
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>
            </Box>
            <Box sx={{ minWidth: 120, marginTop: 3, marginBottom: 3 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={6}>
                  <Typography sx={{ marginBottom: 1 }}>Blood Groop</Typography>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Select Blood Groop
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={bloodGroup}
                      label="Blood Group"
                      onChange={handleBloodGroupChange}
                    >
                      <MenuItem value="A(+ve)">A+</MenuItem>
                      <MenuItem value="A(-ve)">A-</MenuItem>
                      <MenuItem value="AB(+ve)">AB+</MenuItem>
                      <MenuItem value="AB(-ve">AB-</MenuItem>
                      <MenuItem value="B(+ve)">B+</MenuItem>
                      <MenuItem value="B(-ve">B-</MenuItem>
                      <MenuItem value="O(+ve)">O+</MenuItem>
                      <MenuItem value="O(-ve">O-</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <Typography sx={{ marginBottom: 1 }}>ID No.</Typography>
                  <TextField
                    label="XXXX"
                    variant="outlined"
                    type="string"
                    fullWidth
                    name="idNo"
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>
            </Box>

            <Box sx={{ marginTop: 3 }}>
              <Typography sx={{ marginBottom: 1 }}>
                ফোন নম্বর/Phone Number
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={6}>
                  <TextField
                    label="বাংলাতে লিখুন"
                    variant="outlined"
                    fullWidth
                    name="phoneNumBn"
                    value={formData.phoneNumBn}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <TextField
                    label="Enter the text in English"
                    variant="outlined"
                    fullWidth
                    name="phoneNumEn"
                    value={formData.phoneNumEn}
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>
            </Box>
            <Box sx={{ marginTop: 3, marginBottom: 3 }}>
              <Typography sx={{ marginBottom: 1 }}>ই-মেইল/E-mail</Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={6}>
                  <TextField
                    label="Enter the text in English"
                    variant="outlined"
                    fullWidth
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <Button
                    variant="contained"
                    component="label"
                    fullWidth
                    sx={{ height: 52.7 }}
                    startIcon={<Photo />}
                    name="photo"
                    value={formData.photo}
                    onChange={handleAvatarChange}
                  >
                    <input type="file" />
                  </Button>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <Typography sx={{ marginBottom: 1 }}>Priority</Typography>
                  <TextField
                    label="Only unique number"
                    variant="outlined"
                    type="number"
                    fullWidth
                    name="serial"
                    value={formData.serial}
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>
            </Box>

            <Button
              onClick={handleClick}
              sx={{ marginTop: 2 }}
              variant="contained"
              startIcon={<PersonAdd />}
            >
              {loading ? "Adding..." : "Add Staff"}
            </Button>
          </Box>
        </form>
        <ShowStaff staffData={data.staffInfo} handleDelete={handleDelete} />
      </Box>
    </>
  );
};

export default Staff;
